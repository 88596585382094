import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Seo from '../../components/seo';
import ogImage from '../../images/seo/SEO_fundacja.jpg';
import Layout from '../../components/layout/layout';
import './style/style.scss';
import { StaticImage } from 'gatsby-plugin-image';
import ReasonsWhySection from './components/reasons-why-section';
import OurProjectSection from './components/our-project-section';
import OurMissionSection from './components/our-mission-section';
import TestimonialsSection from './components/testimonials-section';
import DevelopWithUsSection from './components/develop-with-us-section';
import AmbassadorSection from './components/ambasador-section';
import WikiSection from './components/wiki-section';
import scrollTo from 'gatsby-plugin-smoothscroll';
import getCurrentTranslations from '../../components/contentful-translator';
import './style/style-old.scss';

const CareerPage = ({ data }) => {
  const { t } = useTranslation();

  // const {language} = React.useContext(I18nextContext);
  // const joblistings = getCurrentTranslations(data.joblistings.edges, language)

  return (
    <Layout withHeader={false}>
      <Seo
        title={t('career.page-title')}
        description={t('career.page-title')}
        ogImage={ogImage}
      />
      <div className="career-header-ctn">
        <StaticImage
          className="header-img-desktop"
          src="../../images/career/career-header.png"
          alt={t`career.header-title`}
          placeholder="none"
          loading="lazy"
        />
        <div className="container header-img-mobile">
          <StaticImage
            src="../../images/career/career-header-mobile.png"
            alt={t`career.header-title`}
            placeholder="none"
            loading="lazy"
          />
        </div>
        <div className="career-header-text-ctn">
          <div className="container career-header-text-inner_ctn">
            <h1>{t`career.header-title`}</h1>
            <p>{t`career.header-text`}</p>
            <button
              onClick={() =>
                scrollTo('#recrutation')
              }>{t`career.header-btn`}</button>
          </div>
        </div>
      </div>

      <div className="career-main-ctn">
        <ReasonsWhySection t={t} />

        <OurProjectSection t={t} />

        <OurMissionSection t={t} />

        <TestimonialsSection t={t} />

        <DevelopWithUsSection
          t={t}
          jobOffers={getCurrentTranslations(data.jobOffers.edges, 'pl')}
        />

        <AmbassadorSection t={t} />

        <WikiSection t={t} />
      </div>
    </Layout>
  );
};

export default CareerPage;
