import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const TestimonialsSection = ({ t }) => (
  <section className="page-section">
    <div className="container">
      <div className="testimonial-ctn">
        <h2>{t`career.testimonials_title`}</h2>
        <p className="under-header-text">{t`career.listen_to_our_ppl`}</p>
        <div className="testimonial-grid">
          <Testimonial
            name={t`career.norbert2`}
            role={t`career.norbert3`}
            text={t`career.norbert1`}
            avatar={
              <StaticImage
                src="../../../images/career/norbert.png"
                alt="Norbert Paszyński"
                placeholder="none"
                loading="lazy"
                className="testimonial-avatar"
              />
            }
            t={t}
          />
          <Testimonial
            name={t`career.daria2`}
            role={t`career.daria3`}
            text={t`career.daria1`}
            avatar={
              <StaticImage
                src="../../../images/career/daria.png"
                alt="Daria"
                placeholder="none"
                loading="lazy"
                className="testimonial-avatar"
              />
            }
            t={t}
          />
          <Testimonial
            name={t`career.tomasz2`}
            role={t`career.tomasz3`}
            text={t`career.tomasz1`}
            avatar={
              <StaticImage
                src="../../../images/career/tomasz.png"
                alt="Tomasz"
                placeholder="none"
                loading="lazy"
                className="testimonial-avatar"
              />
            }
            t={t}
          />
          <Testimonial
            name={t`career.natalia2`}
            role={t`career.natalia3`}
            text={t`career.natalia1`}
            avatar={
              <StaticImage
                src="../../../images/career/Przybylo.png"
                alt="Przybylo"
                placeholder="none"
                loading="lazy"
                className="testimonial-avatar"
              />
            }
            t={t}
          />
          <Testimonial
            name={t`career.marta2`}
            role={t`career.marta3`}
            text={t`career.marta1`}
            avatar={
              <StaticImage
                src="../../../images/career/marta.png"
                alt="Marta"
                placeholder="none"
                loading="lazy"
                className="testimonial-avatar"
              />
            }
            t={t}
          />
        </div>
      </div>
    </div>
  </section>
);

const Testimonial = ({ t, name, role, avatar, text }) => (
  <div className="testimonial">
    <p>{text}</p>
    <div className="person-ctn">
      {avatar}
      <div>
        <h4>{name}</h4>
        <h6>{role}</h6>
      </div>
    </div>
  </div>
);

export default TestimonialsSection;
