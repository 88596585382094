import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-react-i18next';
import VideoPlayedInModal from '../../../components/video-played-in-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const OurMissionSection = ({ t }) => (
  <section className="page-section our-mission-ctn">
    <div className="container">
      <div className="our-mission-intro-ctn">
        <div className="our-mission-text-ctn">
          <h2>{t`career.our_mission1`}</h2>
          <h2>
            {t`career.our_mission1_2`} <span>{t`career.our_mission2`}</span>
          </h2>
          <p>{t`career.our_mission_text`}</p>
          <Link to="/about-us" className="read-more-btn">
            {t`career.read_more`}&nbsp;&nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
        <div className="our-mission-img-ctn">
          <StaticImage
            src="../../../images/career/our-idea-man.png"
            alt="Our idea, check it out"
            placeholder="none"
            loading="lazy"
          />
        </div>
      </div>
      <div className="our-mission-video-ctn">
        <VideoPlayedInModal
          src="https://www.youtube.com/embed/wWDIn2v3I4k"
          title="Why it is worth applying to the MedBiz Innovations Program?"
          placeholder={
            <StaticImage
              src="../../../images/career/video-placeholder.png"
              alt="Video Placeholder"
              placeholder="none"
              loading="lazy"
            />
          }
        />
        <div className="meet-us_ctn">
          <h3>
            {t`career.we_the_ppl1`} <span>{t`career.we_the_ppl2`}</span>
          </h3>
          <Link to="/team">{t`career.meet_us`}</Link>
        </div>
      </div>
    </div>
  </section>
);

export default OurMissionSection;
