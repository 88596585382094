import React from 'react';
import Modal from "react-modal";
import Video from './video';
import { StaticImage } from 'gatsby-plugin-image';
import './style/video-played-in-modal.scss'


const VideoPlayedInModal = ({src, title, placeholder}) => {

  const [isModal, setIsModal] = React.useState(false)
  return (
    <div>
      <Modal
        isOpen={isModal}
        style={boxStyles}
        onRequestClose={() => setIsModal(false)}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        preventScroll={true}
        className="video-modal-content_ctn"
      >
        <div style={{height: '100%', width: '100%'}} onClick={() => setIsModal(false)}/>
        <Video
          src={src}
          title={title}
        />
        <div style={{height: '100%', width: '100%'}} onClick={() => setIsModal(false)}/>
      </Modal>
      <button
        onClick={() => setIsModal(true)}
        className="video-played-in-modal_ctn"
      >
        {placeholder}
        <div className="video-placeholder-gradient">
          <StaticImage
            src="../images/general/video_play_btn.png"
            alt="Video play button"
            placeholder="none"
            loading="lazy"
            className="video-play-btn"
          />
        </div>
      </button>
    </div>
  );
}

const boxStyles = {
  overlay: {
    zIndex: 103,
    background: 'rgba(0,0,0,0.6)',
  },
};

export default VideoPlayedInModal;
