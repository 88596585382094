import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const DevelopWithUsSection = ({ t, jobOffers }) => {
  return (
    <section className="page-section">
      <div className="container">
        <div className="develop-with-us-ctn" id="recrutation">
          <h2>
            <span>{t`career.develop1`} </span>
            {t`career.develop2`}
          </h2>
          <p
            className="under-header-text"
            style={{
              marginBottom: '4rem'
            }}>{t`career.our_current_positions`}</p>
          {jobOffers.map((job) => (
            <JobPosition data={job} t={t} />
          ))}
        </div>
      </div>
    </section>
  );
};

const JobPosition = ({ t, data }) => {
  if (data === undefined) return null;

  return (
    <div className="job-position-ctn">
      <div className="job-img-ctn">
        <img
          src={data.node?.jobIcon?.file?.url}
          alt="close icon"
          width="40px"
          height="40px"
        />
      </div>
      <div className="job-text-ctn">
        <h4>{data.node?.title}</h4>
        <p>{data.node?.description?.description}</p>
      </div>
      <a
        href={data.node?.linkToForm}
        target="_blank"
        rel="noreferrer"
        className="read-more-btn">
        {t`career.sign_up`}&nbsp;&nbsp;
        <FontAwesomeIcon icon={faArrowRight} />
      </a>
    </div>
  );
};

export default DevelopWithUsSection;
