import { graphql } from 'gatsby';
import CareerPage from '../views/career';

export default CareerPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    jobOffers: allContentfulRekrutacje {
      edges {
        node {
          title
          description {
            description
          }
          linkToForm
          jobIcon {
            file {
              url
            }
          }
          node_locale
        }
      }
    }
  }
`;
