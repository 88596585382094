import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const WikiSection = ({ t }) => (
  <section className="page-section" style={{ position: 'relative' }}>
    <StaticImage
      className="career-theme4"
      src="../../../images/career/theme4.png"
      alt="Theme 4"
      placeholder="none"
      loading="lazy"
    />
    <div className="container">
      <div className="wiki-ctn">
        <StaticImage
          src="../../../images/career/krzysiek.png"
          alt="Wiki recruiter"
          placeholder="none"
          loading="lazy"
        />
        <div className="wiki-text-ctn">
          <h2>
            {t`career.write_to_wiki1`} <span>{t`career.write_to_wiki2`}</span>
          </h2>
          <h2>{t`career.write_to_wiki3`}</h2>
          <p>{t`career.write_to_wiki_text`}</p>
          <a href="mailto:kzachwieja@innovationshub.pl">
            <div className="wiki-icon">
              <StaticImage
                src="../../../images/career/mail-icon.png"
                alt="Email icon"
                placeholder="none"
                loading="lazy"
              />
            </div>
            <a href="mailto:kzachwieja@innovationshub.pl">
              kzachwieja@innovationshub.pl
            </a>
          </a>
          <div style={{ height: '0.5rem' }} />
          <a href="https://www.linkedin.com/in/krzysztof-zachwieja">
            <div className="wiki-icon">
              <StaticImage
                src="../../../images/career/linkedin-icon.png"
                alt="Linkedin icon"
                placeholder="none"
                loading="lazy"
              />
            </div>
            <a href="https://www.linkedin.com/in/krzysztof-zachwieja">
              linkedin.com/in/krzysztof-zachwieja
            </a>
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default WikiSection;
