import React from 'react';

const ReasonDot = ({ text }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div className="reason-dot" />
    <p>{text}</p>
  </div>
);

export default ReasonDot;
