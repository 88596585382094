import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const OurProjectSection = ({ t }) => (
  <section className="page-section">
    <div className="container reasons-why-ctn">
      <h2>{t('career.you_decide')}</h2>
      <div className="our-projects-ctn">
        <div>
          <StaticImage
            src="../../../images/logos/inhub/mentoring_dark.png"
            alt="MedBiz Logo Dark"
            placeholder="none"
            loading="lazy"
          />
          <Link to="/mentoring-program">
            {t`career.more_info`}&nbsp;&nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
        <div>
          <StaticImage
            src="../../../images/logos/inhub/incubator_dark.png"
            alt="Incubator Logo Dark"
            placeholder="none"
            loading="lazy"
            style={{ margin: '0 1rem' }}
          />
          <Link to="/incubator">
            {t`career.more_info`}&nbsp;&nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
        <div>
          <StaticImage
            src="../../../images/logos/inhub/Akademia_Przyszlosci_Logo_ciemne.png"
            alt="Akademia Przyszłości"
            placeholder="none"
            loading="lazy"
            style={{ margin: '0 1rem' }}
          />
          <Link to="/future-academy">
            {t`career.more_info`}&nbsp;&nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
        <div>
          <StaticImage
            src="../../../images/logos/inhub/academy_dark.png"
            alt="Academy Logo Dark"
            placeholder="none"
            loading="lazy"
          />
          <Link to="/news">
            {t`career.more_info`}&nbsp;&nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
        <div>
          <StaticImage
            src="../../../images/logos/inhub/ami_dark.png"
            alt="Akademia Młodego Innowatora Logo Dark"
            placeholder="none"
            loading="lazy"
          />
          <Link to="/news/workshops">
            {t`career.more_info`}&nbsp;&nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
        {/* <div>
          <StaticImage
            src="../../../images/logos/inhub/gic_dark.png"
            alt="Green Innovations Challenge Logo Dark"
            placeholder="none"
            loading="lazy"
          />
          <Link to="/green-innovations-challenge">
            {t`career.more_info`}&nbsp;&nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div> */}
      </div>
    </div>
  </section>
);

export default OurProjectSection;
