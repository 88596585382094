import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {withPrefix} from "gatsby";

const AmbassadorSection = ({ t }) => (
  <section className="page-section" style={{ position: 'relative' }}>
    <div style={{ marginTop: '6rem' }} />
    <StaticImage
      src="../../../images/career/ambasadors.png"
      alt="Become ambassador"
      placeholder="none"
      loading="lazy"
      className="ambassadors-img"
    />
    <div className="ambassadors-career-ctn">
      <div className="container ">
        <div>
          <h2 style={{ margin: '0' }}>
            {t`career.become_ambassador1`}{' '}
            <span>{t`career.become_ambassador2`}</span>
          </h2>
          <p
            style={{
              margin: '2rem 0'
            }}>{t`career.about_ambassador_program`}</p>
          <div className="btn-ctn">
            <a href={withPrefix('Program Ambasadorski 2023.pdf')} target="_blank" rel="noreferrer" className="read-more-btn full-bg">
              {t`career.read_more2`}
            </a>
            <Link to="https://forms.gle/RgiXze1ndUqWDQBu6" className="read-more-btn">
              {t`career.join_us`}&nbsp;&nbsp;
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default AmbassadorSection;
