import React from 'react';
import ReasonDot from './reason-dot';
import { StaticImage } from 'gatsby-plugin-image';

const ReasonsWhySection = ({ t }) => (
  <section className="page-section" style={{ position: 'relative' }}>
    <StaticImage
      className="career-theme1"
      src="../../../images/career/theme1.png"
      alt="Theme 1"
      placeholder="none"
      loading="lazy"
    />
    <StaticImage
      className="career-theme2"
      src="../../../images/career/theme2.png"
      alt="Theme 2"
      placeholder="none"
      loading="lazy"
    />
    <StaticImage
      className="career-theme3"
      src="../../../images/career/theme3.png"
      alt="Theme 3"
      placeholder="none"
      loading="lazy"
    />
    <div className="container reasons-why-ctn">
      <h2>{t('career.plenty-reasons')}</h2>
      <div className="reason-ctn">
        <div className="text-ctn">
          <h4>
            {t('career.reason1_1')} <span>{t('career.reason1_2')}</span>{' '}
            {t('career.reason1_3')}
          </h4>
          <ReasonDot text={t('career.networking_reason_1')} />
          <ReasonDot text={t('career.networking_reason_2')} />
          <ReasonDot text={t('career.networking_reason_3')} />
        </div>
        <div className="img-ctn">
          <StaticImage
            src="../../../images/career/networking.png"
            alt="Networking"
            placeholder="none"
            loading="lazy"
          />
        </div>
      </div>
      <div className="reason-ctn">
        <div className="img-ctn">
          <StaticImage
            src="../../../images/career/expirience.png"
            alt="Experience"
            placeholder="none"
            loading="lazy"
          />
        </div>
        <div className="text-ctn">
          <h4>
            {t('career.reason2_1')} <span>{t('career.reason2_2')}</span>{' '}
            {t('career.reason2_3')}
          </h4>
          <ReasonDot text={t('career.networking_reason2_1')} />
          <ReasonDot text={t('career.networking_reason2_2')} />
          <ReasonDot text={t('career.networking_reason2_3')} />
        </div>
      </div>
      <div className="reason-ctn">
        <div className="text-ctn">
          <h4>
            {t('career.reason3_1')} <span>{t('career.reason3_2')}</span>{' '}
            {t('career.reason3_3')}
          </h4>
          <ReasonDot text={t('career.networking_reason3_1')} />
          <ReasonDot text={t('career.networking_reason3_2')} />
        </div>
        <div className="img-ctn">
          <StaticImage
            src="../../../images/career/ideas.png"
            alt={t`career.header-title`}
            placeholder="none"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </section>
);

export default ReasonsWhySection;
